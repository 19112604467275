/* For AI Prompt */
// All the constants:
window.chatPrompt = {
	prompt: "Using the following Case description submitted by a potential customer, what follow up high level (maximum three listed in bullet points) most important questions would you ask in an SMS format in order to qualify the case and connect them to the right attorney? Note that we already have the zip code of the incident so we don't need the location\n    Name: {{name}}\n    Case Description: {{case_details}}",
	context: "Act as a legal office intake assistant whose job is to qualify the legal case leads submitted on our legal content website for a free consultation.",
	model: 'gpt-4'
};
//server side API url:
const CHAT_API_URL = "https://tools.nolo.com/public/form-prompt";
const FORM_CACHE_API_URL = "https://tools.nolo.com/form-lead";
const SPAM_CHAR_LIMIT = 1000;

// Universal Form Version
const ERROR_COMPLETION_LINE = "OpenAI completion failed";

// user visiting history in session
userTrackKey = window.userTrackKey || 'userWebJourney';

const showModal = true;

function submitToForm(parentWrapper, source) {

	// send out Remove cache request
	var reqHander = new Headers();
	reqHander.append("Content-Type", "application/json");
	reqHander.append("X-CSRF-TOKEN", $("form input[name=_token]")[0].value);

	console.log("submitToForm -- parentWrapper: ", parentWrapper);

	var user_name = parentWrapper.querySelector("[name=name]").value.replace(/\s+/g, ' ');
	// if (!parentWrapper.querySelector("[name=name]").value.includes(' ')) {
	// 	user_name += ' LNU'; //last name undefined 
	// } else if (parentWrapper.querySelector("[name=name]").value.split(' ')[1].length <= 0) {
	// 	user_name += 'LNU';
	// }

	var raw = {
		// "_token": $("input[name=_token]")[0].value,
		"name": user_name,
		"email": parentWrapper.querySelector("[name=email]").value,
		"phone": parentWrapper.querySelector("[name=phone]").value,
		"zip": parentWrapper.querySelector("[name=zip]").value,
		"description": parentWrapper.querySelector("[name=description]").value,
		"loc": parentWrapper.querySelector("[name=loc]").value,
		"lead_id": parentWrapper.querySelector("[name=lead_id]").value,
		"WebsiteID": "15-103-212-197-7-216-247-236",
		"ai_case_id": window.ai_case_id
	};

	raw['sms_optin_status'] = parentWrapper.querySelector("[name=sms_optin_status]").value;
	raw['jornaya_id'] = parentWrapper.querySelector("#leadid_token").value;
	raw['trusted_form_id'] = parentWrapper.querySelector("[name=trusted_form_id]").value;
	
	if (source == 'submit') {
		raw['ai'] = [];
		document.querySelectorAll('.ai-radio-label').forEach(element => {
			var radioFor = element.getAttribute('for');
			radioFor = radioFor.replace('[', '\\[');
			radioFor = radioFor.replace(']', '\\]');

			var radioAnswer, radioLabel = '', radioAnswer = '';
			try {
				radioLabel = document.querySelector('#' + formPrefix + "chat-content label[for=" + radioFor + "]").innerText;
				radioAnswer = document.querySelector('#' + formPrefix + "chat-content input[name=" + radioFor + "]:checked").value;
			} catch (error) {
				radioAnswer = 'undefined';
			}
			raw['ai'].push({'question': radioLabel, 'answer': radioAnswer});
		});
	}

	var requestOptions = {
		method: 'POST', //'DELETE',
		headers: reqHander,
		body: JSON.stringify(raw),
		redirect: 'follow'
	};

	window.cacheTimeStamp = "";

	if (window.gptAbort == true) {
		console.log("aborting GPT, no AI Qs");
		// parentWrapper.querySelector(".submit-error").classList.remove('d-none'); 
		// $('#uform-universalFormPromptModal').modal('hide');
		
		// if we failed uformMCQ, then directly submit
		fetch('/uformSubmit', requestOptions)
			.then(function (response) {
				return response.text()
			}).then(function (data) {
				console.log('moving on to success')
				window.promptSubmit.click();
			}).catch(function (error) {
				console.error(error)
			});
	} else {
		fetch('/uformSubmit', requestOptions)
			.then(function (response) {
				return response.text()
			}).then(function (data) {
				console.log('moving on to success')
				window.promptSubmit.click();
			}).catch(function (error) {
				console.error(error)
			});
	}

	// hit the submit btn or close the window    
	if (["x", "close"].includes(source)) {
		// https://jira.internetbrands.com/browse/EHCS-3415
		if (window.modelOpen && window.modelOpen == "uquiz") {
			// close quiz model and show typ step
			// show step-4
			$('#uquiz-universalFormPromptModal').modal('hide');
			document.querySelector(".modal-progress_bar").setAttribute("data-progress-step", "4");
			// clear form
			window.parentWrapper.querySelectorAll(".modal-body_main-step input").forEach(function (i) {
				i.value = ""
			})
			window.parentWrapper.querySelectorAll(".modal-body_main-step textarea").forEach(function (i) {
				i.value = ""
			})
			document.getElementById(quizPrefix + "loading").classList.remove("d-none");
			document.getElementById(quizPrefix + "chat-wrapper").classList.add("d-none");
		} else {
			delete window.modelOpen
			//redirect to typ
			// window.location.replace("https://www.nolo.com/uform/success");
		}
		delete window.modelOpen
	} else {
		// $('#uform-universalFormPromptModal').modal('hide');
		if (typeof promptModalQuiz !== 'undefined') {
			// $('#uquiz-universalFormPromptModal').modal('hide');
			displayLoadingForSubmit();
		}
		displayLoadingForSubmit();
		// window.promptSubmit.click();
	}
}

// Tracking function
function triggerTrack(link_name, quiz = false) {
	if (typeof gtag == 'undefined') {
		function gtag() {
			dataLayer.push(arguments);
		}
	}
	var selector, journey_name;
	if (quiz) {
		journey_name = 'Form_Top';
	} else {
		var form = parentWrapper.querySelector("form");
		var type = form.getAttribute("onsubmit").split(",")[1].replaceAll("'", "").replaceAll(" ", "");
		var selector, journey_name;
		if (type == 'desktop') {
			selector = '.form-connect-with-attorneys.desktop-form';
			journey_name = 'Form';
		} else if (type == 'mobile') {
			selector = '.form-connect-with-attorneys.mobile-form';
			journey_name = 'Form';
		} else if (type == 'top') {
			selector = '.form-connect-with-attorneys.top-form';
			journey_name = 'Form_Top';
		} else if (type == 'bottom') {
			selector = '.form-connect-with-attorneys.bottom-form';
			journey_name = 'Form_Bottom';
		}

	}
	var aop_id = document.getElementById('aop_abbreviation').value;
	gtag('event', 'optimize_journey', {
		'journey_name': aop_id + '_' + journey_name,
		'step_name': aop_id + '_universalform',
		'link_name': link_name,
		'send_to': 'G-RJLCGB9QZ9'

	});
}

// function getJornaya() {
// 	let id = "";
// 	document.querySelectorAll("#leadid_token").forEach(function (i) {
// 		if (id.length == 0 && i.value) id = i.value;
// 	});

// 	return id;
// }

// hide the real submit button
var formDescriptions = document.querySelectorAll(".find-a-lawyer-widget textarea[name=description]");
// hide the real click here button
var formClickHeres = document.querySelectorAll(".find-a-lawyer-widget #universal-form-click-here");

// hide the real submit button
var formSubmitBtns = document.querySelectorAll(".find-a-lawyer-widget button[type=submit]");


var promptModal;
var formPrefix = "uform-";

if (document.getElementById('aop_abbreviation') && document.getElementById('aop_abbreviation').value !== 'SSDI') {
	startChat();
}

function startChat() {
	// if (!document.querySelector(".find-a-lawyer-widget textarea[name=description]")) injectCaseField();
	if (!document.querySelector("#submit-to-prompt")) {
		formSubmitBtns.forEach(function (formSubmitBtn) {
			injectCaseButton(formSubmitBtn);
		});
	}

	if (!document.querySelector("#universal-form-click-here-ai")) formClickHeres.forEach(function (formClickHere) {
		injectClickHere(formClickHeres)
	})
	// if (!document.getElementById(`${formPrefix}universalFormPromptModal`) && showModal) bindModalEvents();
	// bind the modal to BS library:
	bindModalEvents();
	// promptModal = new bootstrap.Modal(document.getElementById(`${formPrefix}universalFormPromptModal`), {backdrop:'static'});
}


// create a prompt casedetails textarea to collect the first hand case description from universal form which should look exact same as case description field:'
function injectClickHere(formClickHeres) {
	formClickHeres.forEach(function (formClickHere) {
		if (formClickHere.closest(".universal-form-top") && formClickHere.closest(".universal-form-top").parentNode.querySelector('#universal-form-modal')) {
			// using uquiz-injection
		} else {
			if (formClickHere.parentElement.parentElement.querySelectorAll("#universal-form-click-here-ai").length>=1) {
				return;
			} else {
				var clickHereDiv = document.createElement("div");
				clickHereDiv.innerText = "Click Here"
				clickHereDiv.setAttribute("id", "universal-form-click-here-ai")
				formClickHere.classList.add("d-none");
				// Adding the prompt continue button to the page:
				formClickHere.insertAdjacentElement("afterend", clickHereDiv);
				clickHereDiv.addEventListener("mousedown", function () {
					event.preventDefault();
                    formClickHere.parentElement.parentElement
					var parentWrapper = event.currentTarget.closest(".find-a-lawyer-widget");
                    parentWrapper.querySelector('input[name="sms_optin_status"]').value = '0';
					aiPromptSubmission(parentWrapper, formClickHere)
				})
			}
		}
	});
}

// create a prompt casedetails textarea to collect the first hand case description from universal form which should look exact same as case description field:'
// function injectCaseField() {
// 	formDescriptions.forEach(function (formDescription) {
// 		if (formDescription.closest(".universal-form-top") && formDescription.closest(".universal-form-top").parentNode.querySelector('#universal-form-modal')) {
// 			// using uquiz-injection
// 		} else {
// 			var promptCaseField = document.createElement("textarea");
// 			promptCaseField.classList.add("form-control");
// 			promptCaseField.setAttribute("style", "max-width:100%; flex-basis:100%;");
// 			promptCaseField.setAttribute("row", "7");
// 			promptCaseField.setAttribute("name", "casedetails");
// 			promptCaseField.setAttribute("placeholder", "Please describe your legal situation in as much detail as possible.");
// 			formDescription.classList.add("d-none");
// 			// Adding the prompt continue button to the page:
// 			formDescription.insertAdjacentElement("afterend", promptCaseField);
// 			promptCaseField.addEventListener("input", function () {
// 				promptCaseField.parentNode.classList.add("done");
// 				formDescription.value = promptCaseField.value;
// 				promptCaseField.classList.remove('error')
// 			})
// 		}
// 	});
// }

// create prompt continue button which should look exact same as submit button:

// button to replace "real" submission for AI prompt
function injectCaseButton(formSubmitBtn) {
	var promptContinueBtn = document.createElement("button");
	promptContinueBtn.classList.add("btn");
	promptContinueBtn.classList.add("btn-CTA");
	promptContinueBtn.classList.add("find-a-lawyer-button");
	promptContinueBtn.setAttribute("id", "submit-to-prompt");
	promptContinueBtn.innerText = formSubmitBtn.innerText.replaceAll("\n", "");
	promptContinueBtn.type = "button";
	// event of click: post the questionnaire to the API, get the answer from AI
	promptContinueBtn.addEventListener("mousedown", async function (event) {
		event.preventDefault();
		var parentWrapper = event.currentTarget.closest(".find-a-lawyer-widget");
		aiPromptSubmission(parentWrapper, formSubmitBtn)
	})

	formSubmitBtn.classList.add("d-none");
	// Adding the prompt continue button to the page: Parent Node:.button-container
	formSubmitBtn.parentNode.appendChild(promptContinueBtn);
}

async function aiPromptSubmission(parentWrapper, formSubmitBtn) {

	// disable the button and wait for the result:
	parentWrapper.querySelector("form #submit-to-prompt").disabled = true;
	parentWrapper.querySelector("form #universal-form-click-here-ai").disabled = true;

	//collect all answers:
	// var name = parentWrapper.querySelector("[name=name]").value;
	// var phone = parentWrapper.querySelector("[name=phone]").value;
	// var email = parentWrapper.querySelector("[name=email]").value;
	// var zip = parentWrapper.querySelector("[name=zip]").value;
	// var caseDetails = parentWrapper.querySelector("[name=description]").value;

	var form = parentWrapper.querySelector("form");
	var type = form.getAttribute("onsubmit").split(",")[1].replaceAll("'", "").replaceAll(" ", "");

	var validation = await validateForm(event, type, form);

	if (validation == true) {

		// Post information to the OpenAI
		var reqHander = new Headers();
		reqHander.append("Content-Type", "application/json");
		reqHander.append("X-CSRF-TOKEN", $("form input[name=_token]")[0].value);

		// Looking for session storage for the information
		var curUserTracking = JSON.parse(sessionStorage.getItem(userTrackKey));

		// handle the case where homepage and bottom uform can submit with just one name
		var user_name = parentWrapper.querySelector("[name=name]").value.replace(/\s+/g, ' ');

		// need to generate unique id ("ai_case_id")
		window.ai_case_id = Math.floor(Math.random() * (1e13 + 1e12 +1)) + 1e12;

		var raw_small = {
			"zip": parentWrapper.querySelector("[name=zip]").value,
			"description": parentWrapper.querySelector("[name=description]").value,
			"prompt": "generic_questions",
			"ai_case_id": window.ai_case_id
		}

		var raw = {
			"name": user_name,
			"email": parentWrapper.querySelector("[name=email]").value,
			"phone": parentWrapper.querySelector("[name=phone]").value,
			"zip": parentWrapper.querySelector("[name=zip]").value,
			"description": parentWrapper.querySelector("[name=description]").value,
			"loc": parentWrapper.querySelector("[name=loc]").value,
			"WebsiteID": "15-103-212-197-7-216-247-236",
			"prompt": "generic_questions",
			'cache_lead': true,
			"ai_case_id": window.ai_case_id
		};
		raw['sms_optin_status'] = parentWrapper.querySelector("[name=sms_optin_status]").value;
		raw['jornaya_id'] = parentWrapper.querySelector("#leadid_token").value;
		raw['trusted_form_id'] = parentWrapper.querySelector("[name=trusted_form_id]").value;

		var body = JSON.stringify(raw);

		window.promptSubmit = formSubmitBtn;

		var requestOptions = {
			method: 'POST',
			headers: reqHander,
			body: body,
			redirect: 'follow'
		};

		var requestOptionsSmall = {
			method: 'POST',
			headers: reqHander,
			body: JSON.stringify(raw_small),
			redirect: 'follow'
		};

		if (parentWrapper.querySelector("[name=description]").value.length >= SPAM_CHAR_LIMIT ) {
			var source;
			if (parentWrapper.querySelector("[name=description]").value.length >= SPAM_CHAR_LIMIT) {
				source = "long-desc";
			} else source = "error"
			// console.log("parentWrapper -- ", parentWrapper);
			submitToForm(parentWrapper, source);
		} else {
			$('#uform-universalFormPromptModal').modal('show');
			$('#bottom-right-black-bubble-invite-container').addClass("move-back");
			$('.ngageMobileFloat').addClass("move-back");
			document.getElementById(formPrefix + "prompt-form").setAttribute("disabled",true);

			fetch('/uformMCQ', requestOptionsSmall)
				.then(function (response) {
					return response.json()
				})
				.then(function (data) {
					if (data && data.status == 200) {
						var mcqQuestions = data.responseBody.questions;
						if (showModal) {
							document.getElementById(formPrefix + "loading").classList.add("d-none");
							// open modal with AI questions:

							// create radio questions from tools GPT MCQ response
							for (let i = 0; i < mcqQuestions.length; i++) {
								// create single question container
								var questionContainer = document.createElement('div');
								questionContainer.classList.add("ai-question-container");

								// create label and append ^
								var questionLabel = document.createElement('label');
								questionLabel.innerHTML = mcqQuestions[i].text;
								questionLabel.classList.add('ai-radio-label');
								questionLabel.setAttribute('for', 'aiQuestion['+i+']');
								questionContainer.appendChild(questionLabel);

								// create radioContainer (contains input + label)
								var radioContainer = document.createElement('div');
								radioContainer.classList.add('ai-radio-container');

								// create radio buttons and append ^
								for (let j = 0; j < mcqQuestions[i].options.length; j++) {
									var radioSelection = document.createElement('div');
									var radioInput = document.createElement('input');
									radioInput.setAttribute('type', 'radio');
									radioInput.setAttribute('class', 'radio-input');
									radioInput.setAttribute('id', 'aiQuestion['+i+']_'+j);
									radioInput.setAttribute('name', 'aiQuestion['+i+']');

									// just use obj.answer
									// try {
										radioInput.setAttribute('value', mcqQuestions[i].options[j].answer);
									// } catch (error) {
									//     console.log("using .answer", error);
									//     radioInput.setAttribute('value', mcqQuestions[i].options[j].answer);
									// }
									
									//styling class
									radioInput.addEventListener('change', function(event) {
										if (event.target.checked) {
											document.querySelector("#uform-prompt-form").classList.add("interacted");
											document.querySelector("#uform-prompt-form").removeAttribute("disabled");
										}
									});
									
									//now the label
									var radioLabel = document.createElement('label');
									radioLabel.setAttribute('class', 'radio-label');
									radioLabel.setAttribute('for', 'aiQuestion['+i+']_'+j);
									radioLabel.innerHTML = mcqQuestions[i].options[j].text;

									radioSelection.appendChild(radioInput);
									radioSelection.appendChild(radioLabel);

									// append 
									radioContainer.appendChild(radioSelection);
								}
								questionContainer.appendChild(radioContainer);

								//^^ append all that to (formPrefix + "chat-content")
								document.getElementById(formPrefix + "chat-content").appendChild(questionContainer);
							}

							// document.getElementById(quizPrefix + "chat-content").innerText = completion;
							document.getElementById(formPrefix + "loading").classList.add("d-none");
							document.querySelector("#uform-universalFormPromptModal .subtitle").classList.remove("d-none");
							document.getElementById(formPrefix + "chat-wrapper").classList.remove("d-none");
							
							window.parentWrapper = parentWrapper;
							document.querySelector('.modal-backdrop').classList.add("darker")
						} else {
							// Show the question as the second step of form, hide all other answer:
							showPromptStep(parentWrapper, completion);
						}
						window.modelOpen = "uform";
						triggerTrack("first_form_submit", false)
					} else {
						console.error('return empty ai response, submit nGage now')
						submitToForm(window.parentWrapper, "error");
					}
				})
				.catch(function (error) {
					console.error('failed getting gpt qs', error);
					// $('#uform-universalFormPromptModal').modal('hide');
					window.gptAbort = true;
					submitToForm(parentWrapper, "error");
				});

			fetch('/uformQueue', requestOptions)
				.then(function (response) {
					return response.json()
				}).then(function (data) {
					parentWrapper.querySelector("[name=lead_id]").value = data.responseBody.lead_id;
					requestOptions.body = JSON.stringify(raw);
				}).catch(function (error) {
					console.error(error)
				});
		}
	} else {
		parentWrapper.querySelector("form #submit-to-prompt").removeAttribute("disabled")
	}
}

//Modal Events
function bindModalEvents() {
	// document.querySelector("#" + formPrefix + "chat-wrapper textarea").addEventListener("input", function (event) {
	// 	event.preventDefault();
	// 	if (event.target.value.length > 0 && (event.target.value.trim() !== '')) document.getElementById(formPrefix + "prompt-form").removeAttribute('disabled')
	// 	else document.getElementById(formPrefix + "prompt-form").setAttribute('disabled', '')
	// })

	// bind the Modal prompt-form button 
	document.getElementById(formPrefix + "prompt-form").addEventListener("mousedown", function (event) {
		if (event.button == 0) {
			event.preventDefault();		
			// append the content to the form case description:
			// document.querySelectorAll('.ai-radio-label').forEach(element => {
			// 	var radioFor = element.getAttribute('for');
			// 	radioFor = radioFor.replace('[', '\\[');
			// 	radioFor = radioFor.replace(']', '\\]');

			// 	var radioAnswer;
			// 	try {
			// 		radioAnswer = document.querySelector('#' + formPrefix + "chat-content input[name=" + radioFor + "]:checked").value;
			// 	} catch (error) {
			// 		radioAnswer = 'undefined';
			// 	}
			// 	window.parentWrapper.querySelector("[name=description]").value += " \n\n " + element.innerText + ' ' + radioAnswer;
			// });
			
			// hit real submission button:
			submitToForm(window.parentWrapper, "submit");
			triggerTrack("ai_prompt_submit", false)
			displayLoadingForSubmit();
		}
	});

	// bind the Modal prompt-form-close button 
	document.getElementById(formPrefix + "prompt-form-close").addEventListener("mousedown", function (event) {
		event.preventDefault();
		triggerTrack("ai_prompt_cancel_clicked", false)
		// $('#uform-universalFormPromptModal').modal('hide');
		displayLoadingForSubmit();

		// hit real submission button:
		submitToForm(window.parentWrapper, "close");
	});

	// bind the Modal close X icon button 
	document.getElementById(formPrefix + "close-modal-icon").addEventListener("mousedown", function (event) {
		event.preventDefault();
		triggerTrack("ai_prompt_x_clicked", false)
		// $('#uform-universalFormPromptModal').modal('hide');
		displayLoadingForSubmit();
		// hit real submission button:
		submitToForm(window.parentWrapper, "x");
	});
}

function displayLoadingForSubmit() {
	document.querySelector("#uform-chat-wrapper").classList.add("d-none");
	document.querySelector("#uform-loading").classList.remove("d-none");
	document.querySelector("#uform-universalFormPromptModal .subtitle").classList.add("d-none");
}